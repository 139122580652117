.item-product{
    width: 300px;
    height:auto;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(86, 27, 251, 0.15);
    border-radius: 10px;
}
.row,.col-8,.col-1{
    padding:0;
    margin: 0;
}
.dates{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:80%;
    height: auto;
}
.dates span{
    font-size: 14px;
}
.desc{
    color:#3F3C3C;
    font-size: 16px;
    margin-bottom: 20px;
}
.item_product{
    width:300px;
    height:auto;
    padding:5px 0px 5px 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(3, 3, 3, 0.15);
    border-radius: 30px;
    margin-top: 10px;
}
.img{
    height: 22px;
    width:22px;
}
.col-4{
    display: flex;
    align-items: center;
    justify-content: center;
}
.basket-heading{
    height: auto;
    width:100%;
    display: flex;
    margin-top: 140px;
    margin-bottom: 30px;
    justify-content:center;
    align-items: center;
}
.basket-heading span{
    font-size: 30px;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    color:#3F3C3C;
}
.group-items{
    /* display: grid;
    width: fit-content; */
    padding: 10px 0px;
    height: auto;
    width:90%;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 500px;
    /* grid-template-columns: repeat(4,300px); */
    /* gap: 10px; */
}
.itemProductTemp{
    margin: 10px 20px 10px 10px;
}
.basket{
    width: 100%;
    min-height:100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.group-baskets{
    display: grid;
    width: fit-content;
    padding: 10px 0px;
    grid-template-columns: repeat(4,300px);
    gap: 10px;
}
.basket-item{
    color:#3F3C3C;
    cursor: pointer;
}
.basket-item:hover{
    background-color:#7466aa;
    color: white;
}

.list-texts{
    display: flex;
    justify-content: flex-start;
    flex-direction:column;
}
.we-desc-item{
   width: 100%;
}
.name-item span{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: #3F3C3C;
}
.item-product:hover .name-item span{
    color:white;
}
.item-product:hover .desc{
    color:white;
}
.item-product:hover .icon svg path{
    stroke:white;
}
.weight-item{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #8C969C;
}
.desc-item{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #6B9A51;
    margin-left: 10px;
}
.image-item{
    width: 64px;
    height: 64px;
}
.icon{
    height:50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.compare{
    width:100%;
    height: auto;
    min-height: 100vh;
    padding: 0px 5%;
}
.drop__down{
    display: flex;
    flex-direction: column;
    width:100%;
    margin-top: 100px;
    height:auto;
}
.drop__down__form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    height:auto;
}
.selected_values{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
}
.basket_1,.basket_2{
    width:48%;
    height: auto;
}
@media screen and (max-width:600px){
    .selected_values{
        flex-direction: column;
    }
    .basket_1,.basket_2{
        width:100%;
        height: auto;
    }
    .itemProductTemp{
        margin: 10px 0px;
    }
}
.choose{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:45%;
    height:auto;
}
.drop__down__form select{
    height:40px;
    width:100%;
    border:1px solid rgb(199, 201, 203);
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 0px 10px;
    background-color: rgba(245, 245, 245, 0.9);
}
select:focus{
    outline: none;
}
@media screen and (max-width:1200px){
    .group-items{
        grid-template-columns: repeat(3,300px);
    }
    .group-baskets{
        grid-template-columns: repeat(3,300px);
    }
}
@media screen and (max-width:900px){
    .group-items{
        grid-template-columns: repeat(2,300px);
    }
    .group-baskets{
        grid-template-columns: repeat(2,300px);
    }
}
@media screen and (max-width:600px){
    .group-items{
        grid-template-columns: repeat(1,300px);
    }
    .group-baskets{
        grid-template-columns: repeat(1,300px);
    }
    .item-product{
        width: 300px;
    }
}