.oneElement{
    height: 50px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid #E6E6E6;
}
.oneElement .col-4{
    justify-content: flex-end;
}
.oneElement:last-child{
    border: none;
}
.element{
    margin-top: 50px;
}
.el-size{
    text-align: right;
    font-family: Lato,sans-serif;
    font-size:16px;
}
.el-name{
    text-align: left;
    font-family: Lato,sans-serif;
    font-size: 16px;
}
.group{
    width: 40%;
    height: auto;
    padding: 20px;
    margin-left: 30%;
    background: #F5F7F6;
    box-shadow: 0px 0px 10px #1c1c1d3b;
    border-radius: 25px;
}
.nameProducts{
    text-align: center;
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 16px;
    color: #7466AA;
}
.el-size-div{
    text-align: right;
}
@media screen and (max-width:600px) {
    .group{
        width: 90%;
        margin-left: 5%;
    }
    .el-name,.el-size{
        font-size: 14px;
    }
}
.row,.col-7, .col-sm-4,.col-sm-3,.col-4{
    padding: 0;
    margin: 0;
}