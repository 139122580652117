:root{
   width: 100%;
   margin: 0;
   padding:0;
}
.row{
    padding: 0;
    margin: 0;
}
.qr-group{
    width:80%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(4,250px);
    gap: 20px;
   display: flex;
   margin-left: 10%;
   justify-content: center;
   flex-wrap: wrap;
   align-items: center;
    min-height: 100vh;
}
.qr-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.qr-item img{
   height:230px;
   width: 230px;
}
.scan{
    height:100vh;
    width:100%;
}
.scanner{
    height:100vh;
    width:100%;
    margin: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.87);
}
.btns{
    z-index: 23;
    position: fixed;
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.btn{
    margin-top: 10vh;
    width:100px;
    border: none;
    box-shadow: 0 0 10px rgba(116, 114, 114, 0.5);
    background-color: rgba(0, 0, 255, 0.466);
}
.qrreader{
    margin-top:20vh;
    height:auto;
    width:100%;
}
.p{
    text-align: center;
    font-size: 16px;
}
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');