@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.item{
    height: auto;
    width:-webkit-max-content;
    width:-moz-max-content;
    width:max-content;
    min-width: 80px;
    border: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 30px;
    text-align: center;
    padding: 10px 30px;
    margin:10px 20px 10px 0px;
}
.howmany{   
    font-size: 18px;  
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    text-align:center;
}
.itemText{
    font-family: Lato,sans-serif;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #3F3C3C;
}
.index{
    margin-top: 150px;
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}
.index-com{
    width: 100%;
    height: auto;
    margin-top: 30px;
    margin-bottom: 50px;
}
.row,.col-12{
    padding: 0;
    margin: 0;
}
.group-content-all{
    display: flex;
    flex-direction: column;
}
.items{
    margin: 0;
}
.group-heading-index{
    display: flex;
    margin-top: 0px;
    margin-bottom: 20px;
    width: 90%;
    margin-left: 5%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}
.list-of-products{
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    overflow-x:auto;
    border-bottom: 1px solid rgba(173, 173, 173, 0.431);
}
::-webkit-scrollbar {
    height: 1px;
    width:1px;
}
.group-desc-set{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}
.group-desc-set span{
    color: #ffffffdc;
    font-family: Lato,sans-serif;
    font-weight: 500;
    font-size: 16px;
    width: auto;
    max-width: 40%;
}
.hr-small-small{
    transform: rotate(90deg);
    height: 2px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    margin: 0px 10px;
    width: 20px;
}
.health-set{
    width: 90%;
    height: auto;
    padding: 20px;
    margin-bottom: 30px;
    margin-top: 30px;
    box-shadow: 0 0 10px rgba(238, 237, 237, 0.5);
    box-sizing: border-box;
    border-radius: 30px;
    background: #6B9A51;
}
.list-of ul{
 list-style: none;
}
.list-of-products img{
    height: 60px;
    width: 60px;
    align-items: center;
    display: flex;
    margin: 0px 13px;
}
.total-weight{
    border-bottom: 1px solid rgba(173, 173, 173, 0.431);
}
.box{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height:70%;
    overflow-y: auto;
}
.show-modal{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top:0;
    z-index:99999;
    background-color:  #8C969C;
}
.total-weight,.show-all{
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    align-items: center;
    justify-content: space-between;
}
.show-all{
    cursor: pointer;
}
.desc-weight{
    font-family: Lato,sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #3F3C3C;
}
.quan-weight{
    font-family: Lato,sans-serif;
    font-weight: 500;
    font-size: 19px;
    color: #898989;
}
.desc-all{
    font-family: Lato,sans-serif;
    font-size: 18px;
    color: #7466AA;
}
.group-content-list{
    width: 90%;
    height: auto;
    background: #F5F7F6;
    padding: 0px 20px;
    box-shadow: 0 0 10px rgba(238, 237, 237, 0.5); 
    border: 1px solid #e9e5e5;
    box-sizing: border-box;
    border-radius: 30px;
}
.group-btns{
    width: 50px;
    display: flex;
    justify-content: space-around;
}
.desc-health-set{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 20px;
    color: #FFFFFF;
}
.btn1:hover path{
    stroke: #7466AA;
}
.btn1:hover rect{
    stroke: #7466AA;
}
.btn2:hover path{
    stroke: #7466AA;
}
.btn2:hover rect{
    stroke: #7466AA;
}
.factor{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 100%;
}
.row,.col-md-3,.col-sm-4,.col-12,.col-11{
    padding: 0;
    margin: 0;
}
.info-icon{
   width: 32px;
   height: 32px; 
}
.group-gr{
 display: flex;
 flex-direction: row;
}
.gr{
    background: #7466AA;
    border-radius: 16px;
    height: auto;
    width:auto;
    padding: 5px 16px;
    color: white;
    margin: 0 0 0 16px;
    font-family: Lato,sans-serif;
    font-style: normal;
    font-size: 12px;
}
.span{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #3F3C3C;
}
.items{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}
.texts{
    width:80%;
    height:auto;
    margin:0;
    padding-bottom: 30px;
}
.healthIndex{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #3F3C3C;
}
.productDesc{
    font-family: Lato,sans-serif;
    font-size: 20px;
    margin-left: 0%;
    margin-right: 0%;
    color: #3F3C3C;
}
.image{
    height:auto;
    width:90%;
}
.imageContent{
    display: flex;
    justify-content: center;
    justify-items: center;
    width: 100%;
    padding-top: 40px;
    height: auto;
}
.group-property{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: 80%;
    margin-bottom: 40px;
}
.property{
    background-color: #f5f3ffc2;
    border:1px solid #7466AA;
    border-radius: 50px;
    margin: 10px 6px;
    padding: 5px 10px;
    height: auto;
    width:-webkit-max-content;
    width:-moz-max-content;
    width:max-content;
    min-width: 60px;
    box-sizing: border-box;
    text-align: center;
}
.property span{
    font-family: Lato,sans-serif;
    font-weight: bolder;
    color:#797575;    
}
.productName{
    width: 100%;
    height:auto;
    margin-left: 0%;
    margin-top: 10px;
    padding-bottom: 30px;
    text-align: center;
}
.iconOfImage{
    margin-top: 20px;
    margin-right: -25px;
}
.icon-star{
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #7466AA;
}
.iconOfImage img{
    height: 12px;
    width: 12px;
}
.name{
    font-family: Lato,sans-serif;
    font-size: 20px;
    font-weight: bolder;
    text-transform: uppercase;
}
.shopId{
    font-family: Lato,sans-serif;
    font-size: 16px;
    color: #3F3C3C;
}
.product-group{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #3F3C3C;
}
@media screen and (min-width:900px){
    .group-heading-index,.health-set,.group-content-list{
        width: 80%;
    }
    .group-heading-index svg{
        width: 30px;
        height: 30px;
    }
    .group-btns{
        width: 70px;
    }
}
@media screen and (max-width:600px){
  .image{
      width: 128px;
      height: 128px;
  }
  .index-com{
      margin-top: 0px;
  }
  .group-heading-index{
      margin-top: 50px;
  }
  .index{
    margin-top: 70px;
  }
  .texts{
      width: 90%;
      margin-left: 5%;
  }
  .product-group{
      font-size: 20px;
  }
  .factor{
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
  }
  .group-gr{
      margin-left: 0;
      margin-top: 20px;
  }
  .items{
      margin:0px 7%;
  }
  .productDesc{
    font-size: 14px;
  }
}
.aca{
    margin-top: 50px;
    width: 90%;
    margin-left: 0%;
    margin-right: 0%;
    align-items: center;
    background: rgba(116, 102, 170, 0.1);
    border-radius: 30px;
    padding: 25px 5px;
    text-align: inherit;
}
.circle-content{
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle{
    width:55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: white;
}
.img{
    height:35px;
    width: 35px;
}

.academicText{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 0px;
    color: #7466AA;
}
.polezen{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
}
.hr{
    transform: rotate(90deg);
    border: 1px solid rgba(236, 236, 236, 0.829);
    height: 0px;
    width: 60px;
}
.row{
    padding: 0;
    margin:0;
}
.nutristeppe{
    width: 100%;
    height: 150px;
    margin-top: 100px;
    background: #7466AA;
    color:white;
    padding:0;
    display: inline-flex;
    align-items: center;
    font-family: Lato,sans-serif;
}
.logoName{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
}
.logoDesc{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight:300;
    font-size: 12px;
    margin-bottom: 0px;
    letter-spacing: 0.16em;
}
.atlas{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight:500;
    font-size: 16px;
    padding-bottom: 0px;
    padding-top: 0px;
    padding-left: 10%;
    padding-right: 10%;
}
.mininutisteppe{
    margin-top: 100px;
}
.academic-content{
    margin-bottom: 0px;
}
.col-5,.col-1,.col-2,.col-sm-1,.col-2,.col-sm-6,.col-12{
    height: auto;
    padding: 0;
    margin: 0;
}
.col-2{
    padding-bottom: 15px;
}
.power{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    padding: 0;
    text-transform: uppercase;
}
.powerDiv{
    margin: 0;
}
.min{
    display: block;
}
@media screen and (max-width:600px){
    .aca{
        margin-left: 5%;
        width: 90%;
        text-align: center;
    }
    .academic-content{
        margin-bottom: 20px;
    }
    .logo{
        display:grid;
        height: auto;
        padding: 20px 20px;
        grid-template-columns: repeat(auto-fill, minmax(min(100%, 250px), 1fr));
    }
    .circle{
       height: 48px;
       width: 48px;
    }
    .polezen{
        text-align: center;
    }
    .nutristeppe{
        height: auto;
        text-align: center;
    }
    .hr{
        transform: none;
         width: 100px;
         margin-bottom: 10px;
    }
    .logoName{
        font-size: 24px;
        text-align: center;
    }
    .mininutisteppe{
        padding: 20px 0px;
        margin-top: 0px;
        background-color: #7466AA;
    }
    .logoDesc{
        font-size: 8px;
    }
    .atlas{
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .power{
        font-size: 8px;
    }
    .min{
        display: none;
    }
}
.navbar{
    width: 100%;
    z-index:99;
    border-bottom: 1px solid #58535348;
    position: fixed;
    top:0;
    left:0;
    height: 80px;
    background-color: white;
}

.setPro{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
}
.navbar-items{
    width: 100%;
    padding: 0px 50px;
    z-index:99;
    position: fixed;
    top:0;
    left:0;
    text-align: center;
    height: 130px;
    background-color:transparent;
} 
.navbar-main{
    width: 100%;
    z-index:99;
    position: fixed;
    top:0;
    left:0;
    height: 50px;
    background-color:transparent;
    }
.hr-small{
    transform: rotate(90deg);
    border: 1px solid rgba(216, 215, 215, 0.829);
    height: 0px;
    border-radius: 1px;
    margin: 0px 10px;
    width: 40px;
}
.logos{
    width:100px;
    display: block;
    height: auto;
}
.group-log-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}
.es{
    width: 250px;
    height: auto;
}
.none{
    display: none;
}
.modal{
    height: 100vh;
    width: 100%;
    display: flex;
    position: fixed;
    top:0;
    left:0;
    z-index:999;
}
.modal .col-7{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.modal-content{
    width:auto;
    height: auto;
    max-width: 250px;
    padding: 10px;
    text-align: center;
    margin-top: 50px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: rgba(19, 18, 18, 0.746);
}
.texts-nav{
    padding: 0;
}
.texts-nav span{
    color: white;
    font-size: 20px;
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: end;
    justify-content: flex-end;
    height: 80px;
    width: 80%;
}
.hr-ver-nav{
    height: 240px;
    width: 24px;
    padding: 0px 10px;
}
.hr-ver-nav .hr{
    height: 240px;
    width: 2px;
    margin-left: 5px;
    transform:none;
    color: white;
}
.hr-ver-nav .ball{
    position: absolute;
    z-index: 56;
    top:40px;
    width: 12px;
    border-radius: 50%;
    height: 12px;
    background-color: #ffffffce;
    border: 1px solid #3F3C3C;
}
.ball:nth-child(1){
    margin-top: 50px;
}
.ball:nth-child(2){
    margin-top: 80px;
}
.ball:last-child{
    margin-top: 160px;
}
.modal-content p:hover{
  background-color: #FFFFFF;
  color: #3F3C3C;
}
.powered{
    display:flex;
    flex-direction: column;
}
.powered span{
    font-size: 20px;
    width: 250px;
    padding-top:5px;
    font-weight: bolder;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    color: #3F3C3C;
}
.row,.col-6,.col-3,.col-2,.col-sm-1,.col-sm-6,.col-md-4,.col-8,.col-sm-10{
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.new-logo{
    width: 70px;
    height: auto;
}
.menu{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.name{
    text-align: center;
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
}
.back{
    width: 10px;
    height: 16px;
    cursor: pointer;
}
@media screen and (max-width:400px){
  .name{
    font-size: 24px;
  }
  .group-log-content{
     flex-direction: column-reverse;
  }
  .hr-small{
     display: none;
  }
}
@media screen and (max-width:300px){
  .name{
    font-size: 20px;
  }
}
.main{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: #3F3C3C;
    text-align: center;
}
.for100{
    background: #7466AA;
    border-radius: 20px;
    padding: 8px 12px;
    color: white;
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
}
.molecular{
    margin-top: 100px;
}
.heading{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 28px;
    text-align: center;
    color: #3F3C3C;
}
@media screen and (max-width:400px){
    .heading{
        font-size: 24px;
    }
}
.oneElement{
    height: 50px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid #E6E6E6;
}
.oneElement .col-4{
    justify-content: flex-end;
}
.oneElement:last-child{
    border: none;
}
.element{
    margin-top: 50px;
}
.el-size{
    text-align: right;
    font-family: Lato,sans-serif;
    font-size:16px;
}
.el-name{
    text-align: left;
    font-family: Lato,sans-serif;
    font-size: 16px;
}
.group{
    width: 40%;
    height: auto;
    padding: 20px;
    margin-left: 30%;
    background: #F5F7F6;
    box-shadow: 0px 0px 10px #1c1c1d3b;
    border-radius: 25px;
}
.nameProducts{
    text-align: center;
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 16px;
    color: #7466AA;
}
.el-size-div{
    text-align: right;
}
@media screen and (max-width:600px) {
    .group{
        width: 90%;
        margin-left: 5%;
    }
    .el-name,.el-size{
        font-size: 14px;
    }
}
.row,.col-7, .col-sm-4,.col-sm-3,.col-4{
    padding: 0;
    margin: 0;
}
.group-links{
    display: inline-flex;
    width: 100%;
    height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.group-link{
    padding-top: 50px;
}
.row,.col-12{
    padding: 0;
    margin: 0;
}
.group-link-span{
    width: 100%;
    text-align: center;
    padding: 0;
}
.ul{
    display:flex;
    flex-direction: row;
    margin-bottom: 0px;
    margin-top: 0px;
    justify-content: space-between;  
}
.li{
    text-decoration: none;
    font-style: normal;
    font-family: Lato,sans-serif;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}
.footer{
    background: #7466AA;
    width: 100%;
    height: auto;
    margin-top: 50px;
    padding: 30px 5% 20px 5%;
}
.inside{
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    background-color: transparent;
}
.shape:hover {
    background-color: #ffffffea;
    box-shadow: 0 0 10px rgba(121, 121, 121, 0.5); 
}
.shape:hover .pSpan{
    color: #7466AA;
}
.shape:hover .spanP{
    color: #3F3C3C;
}
.shape:hover svg path{
    stroke: #3F3C3C;
}
.link{
    text-decoration: none;
}
.shape{
    height: 100px;
    width: 30%;
    display: flex;
    align-content: center;
    padding: 10px 10px 10px 20px;
    margin:0px 10px;
    box-shadow: 0 0 0px rgba(121, 121, 121, 0.5); 
    border: 1px solid rgba(248, 248, 248, 0.671);
    border-radius: 30px;
    cursor: pointer;
}
.textLinks{
    width: 80%;
}
.linkTogo{
    width: 10%;
}
.pSpan{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-size: 21px;
    color:white;
    font-weight: normal;
}
.spanP{
    font-family: Lato,sans-serif;
    letter-spacing: 0.3px;
    font-size: 12px;
    color:rgba(248, 248, 248, 0.89);
    font-weight: lighter;
}
img{
    height: 16px;
    width: 8px;
}
.footerTexts{
    width: 100%;
    text-align: center;
}
.logoName{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    text-align: center;
    color: white;
}
.logoDesc{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight:300;
    font-size: 12px;
    color: white;
    letter-spacing: 0.16em;
}
@media screen and (max-width:600px) {
    .group-links{
        flex-direction: column;
    }
    .shape{
        width: 90%;
        margin: 10px 0px;
    }
    .ul{
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
:root{
   width: 100%;
   margin: 0;
   padding:0;
}
.row{
    padding: 0;
    margin: 0;
}
.qr-group{
    width:80%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(4,250px);
    grid-gap: 20px;
    gap: 20px;
   display: flex;
   margin-left: 10%;
   justify-content: center;
   flex-wrap: wrap;
   align-items: center;
    min-height: 100vh;
}
.qr-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.qr-item img{
   height:230px;
   width: 230px;
}
.scan{
    height:100vh;
    width:100%;
}
.scanner{
    height:100vh;
    width:100%;
    margin: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.87);
}
.btns{
    z-index: 23;
    position: fixed;
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.btn{
    margin-top: 10vh;
    width:100px;
    border: none;
    box-shadow: 0 0 10px rgba(116, 114, 114, 0.5);
    background-color: rgba(0, 0, 255, 0.466);
}
.qrreader{
    margin-top:20vh;
    height:auto;
    width:100%;
}
.p{
    text-align: center;
    font-size: 16px;
}
.preloader{
    width:100%;
    height:100vh;
    display: flex;
    position: fixed;
    top:0;
    left:0;
    background-color: white;
    z-index: 90;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.preloader-mini{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-content{
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 10px 0px;
}
.loader-content-mini{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.loader {
    border-bottom: 2px solid #dce2ec;
    border-radius: 50%;
    border-top: 10px solid #3498db;
    width: 100px;
    height: 100px;
    background-color: white;
    animation: spin 2s linear infinite;
  }
  .loader-mini {
    border-bottom: 1px dotted #dce2ec;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 40px;
    height: 40px;
    background-color: white;
    animation: spin 1s linear infinite;
  }
  .heading{
    width: 100%;
    display: flex;
    height: auto;
    justify-content: center;
    padding: 20px 0px;
  }
  .heading span{
    font-family: Lato,sans-serif;
    font-weight: bolder;
    font-size: 22px;
    padding-top:5px;
    text-transform: uppercase;
    letter-spacing: 0.09em;
    color:#7466AA;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.item-product{
    width: 300px;
    height:auto;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(86, 27, 251, 0.15);
    border-radius: 10px;
}
.row,.col-8,.col-1{
    padding:0;
    margin: 0;
}
.dates{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:80%;
    height: auto;
}
.dates span{
    font-size: 14px;
}
.desc{
    color:#3F3C3C;
    font-size: 16px;
    margin-bottom: 20px;
}
.item_product{
    width:300px;
    height:auto;
    padding:5px 0px 5px 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(3, 3, 3, 0.15);
    border-radius: 30px;
    margin-top: 10px;
}
.img{
    height: 22px;
    width:22px;
}
.col-4{
    display: flex;
    align-items: center;
    justify-content: center;
}
.basket-heading{
    height: auto;
    width:100%;
    display: flex;
    margin-top: 140px;
    margin-bottom: 30px;
    justify-content:center;
    align-items: center;
}
.basket-heading span{
    font-size: 30px;
    font-family: Lato,sans-serif;
    font-weight: bolder;
    color:#3F3C3C;
}
.group-items{
    /* display: grid;
    width: fit-content; */
    padding: 10px 0px;
    height: auto;
    width:90%;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 500px;
    /* grid-template-columns: repeat(4,300px); */
    /* gap: 10px; */
}
.itemProductTemp{
    margin: 10px 20px 10px 10px;
}
.basket{
    width: 100%;
    min-height:100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.group-baskets{
    display: grid;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 0px;
    grid-template-columns: repeat(4,300px);
    grid-gap: 10px;
    gap: 10px;
}
.basket-item{
    color:#3F3C3C;
    cursor: pointer;
}
.basket-item:hover{
    background-color:#7466aa;
    color: white;
}

.list-texts{
    display: flex;
    justify-content: flex-start;
    flex-direction:column;
}
.we-desc-item{
   width: 100%;
}
.name-item span{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: #3F3C3C;
}
.item-product:hover .name-item span{
    color:white;
}
.item-product:hover .desc{
    color:white;
}
.item-product:hover .icon svg path{
    stroke:white;
}
.weight-item{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #8C969C;
}
.desc-item{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #6B9A51;
    margin-left: 10px;
}
.image-item{
    width: 64px;
    height: 64px;
}
.icon{
    height:50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.compare{
    width:100%;
    height: auto;
    min-height: 100vh;
    padding: 0px 5%;
}
.drop__down{
    display: flex;
    flex-direction: column;
    width:100%;
    margin-top: 100px;
    height:auto;
}
.drop__down__form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    height:auto;
}
.selected_values{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
}
.basket_1,.basket_2{
    width:48%;
    height: auto;
}
@media screen and (max-width:600px){
    .selected_values{
        flex-direction: column;
    }
    .basket_1,.basket_2{
        width:100%;
        height: auto;
    }
    .itemProductTemp{
        margin: 10px 0px;
    }
}
.choose{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:45%;
    height:auto;
}
.drop__down__form select{
    height:40px;
    width:100%;
    border:1px solid rgb(199, 201, 203);
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 0px 10px;
    background-color: rgba(245, 245, 245, 0.9);
}
select:focus{
    outline: none;
}
@media screen and (max-width:1200px){
    .group-items{
        grid-template-columns: repeat(3,300px);
    }
    .group-baskets{
        grid-template-columns: repeat(3,300px);
    }
}
@media screen and (max-width:900px){
    .group-items{
        grid-template-columns: repeat(2,300px);
    }
    .group-baskets{
        grid-template-columns: repeat(2,300px);
    }
}
@media screen and (max-width:600px){
    .group-items{
        grid-template-columns: repeat(1,300px);
    }
    .group-baskets{
        grid-template-columns: repeat(1,300px);
    }
    .item-product{
        width: 300px;
    }
}
.main-page{
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    top:0;
    background-image: url(/static/media/bg_main.b501351b.c33a1f95.jpg);
}
.heading-main{
    height: 200px;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    width: 80%;
    flex-direction: row;
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: center;
}
.hr-ver{
    height: 100px;
    width: 1px;
    background-color: white;
    margin-right: 20px;
}
.heading-main span{
    font-size: 30px;
    color:white;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
    font-weight: bold;
}
.main-content-gradient{
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, rgba(69, 2, 255, 0.7) 7.4%, rgba(255, 0, 214, 0.3) 100%);
}
.main-content{
    width: 100%;
    position: fixed;
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: calc( 100% - 140px );
}
.group-logos{
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    position: fixed;
    bottom: 30px;
    width: 90%;
    height:auto;
}
.helper-link{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 250px;
}
.group-logos-item2{
    background: rgba(255, 255, 255, 0.753);
    border-radius:100px;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 80%;
    display: flex;
    max-width: 300px;
    align-items: center;
    justify-content: center;
    height: 48px;
    box-shadow: 0px 0px 10px rgb(34, 34, 34);
}
.group-logos-item1{
    background: linear-gradient(180deg, #5c73c5 0%, #393cddc5 100%);
    border-radius:10px;
    box-sizing: border-box;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    box-shadow: 0 0 20px rgb(255, 254, 254);
}
.logos{
    width:200px;
    height: auto;
}
.group-logos-item1 span{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    padding-top:5px;
    text-transform: uppercase;
    letter-spacing: 0.09em;
    color: white;
}
.group-logos-item2 span{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-weight: bolder;
    font-size: 17px;
    padding-top:5px;
    text-transform: uppercase;
    /* letter-spacing: 0.09em; */
    color: #221e1e;
}
.img{
    height: 60px;
    width: 60px;
}
