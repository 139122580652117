.preloader{
    width:100%;
    height:100vh;
    display: flex;
    position: fixed;
    top:0;
    left:0;
    background-color: white;
    z-index: 90;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.preloader-mini{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-content{
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 10px 0px;
}
.loader-content-mini{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.loader {
    border-bottom: 2px solid #dce2ec;
    border-radius: 50%;
    border-top: 10px solid #3498db;
    width: 100px;
    height: 100px;
    background-color: white;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  .loader-mini {
    border-bottom: 1px dotted #dce2ec;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 40px;
    height: 40px;
    background-color: white;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  .heading{
    width: 100%;
    display: flex;
    height: auto;
    justify-content: center;
    padding: 20px 0px;
  }
  .heading span{
    font-family: Lato,sans-serif;
    font-weight: bolder;
    font-size: 22px;
    padding-top:5px;
    text-transform: uppercase;
    letter-spacing: 0.09em;
    color:#7466AA;
  }
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }