.aca{
    margin-top: 50px;
    width: 90%;
    margin-left: 0%;
    margin-right: 0%;
    align-items: center;
    background: rgba(116, 102, 170, 0.1);
    border-radius: 30px;
    padding: 25px 5px;
    text-align: inherit;
}
.circle-content{
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle{
    width:55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: white;
}
.img{
    height:35px;
    width: 35px;
}

.academicText{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 0px;
    color: #7466AA;
}
.polezen{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
}
.hr{
    transform: rotate(90deg);
    border: 1px solid rgba(236, 236, 236, 0.829);
    height: 0px;
    width: 60px;
}
.row{
    padding: 0;
    margin:0;
}
.nutristeppe{
    width: 100%;
    height: 150px;
    margin-top: 100px;
    background: #7466AA;
    color:white;
    padding:0;
    display: inline-flex;
    align-items: center;
    font-family: Lato,sans-serif;
}
.logoName{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
}
.logoDesc{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight:300;
    font-size: 12px;
    margin-bottom: 0px;
    letter-spacing: 0.16em;
}
.atlas{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight:500;
    font-size: 16px;
    padding-bottom: 0px;
    padding-top: 0px;
    padding-left: 10%;
    padding-right: 10%;
}
.mininutisteppe{
    margin-top: 100px;
}
.academic-content{
    margin-bottom: 0px;
}
.col-5,.col-1,.col-2,.col-sm-1,.col-2,.col-sm-6,.col-12{
    height: auto;
    padding: 0;
    margin: 0;
}
.col-2{
    padding-bottom: 15px;
}
.power{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    padding: 0;
    text-transform: uppercase;
}
.powerDiv{
    margin: 0;
}
.min{
    display: block;
}
@media screen and (max-width:600px){
    .aca{
        margin-left: 5%;
        width: 90%;
        text-align: center;
    }
    .academic-content{
        margin-bottom: 20px;
    }
    .logo{
        display:grid;
        height: auto;
        padding: 20px 20px;
        grid-template-columns: repeat(auto-fill, minmax(min(100%, 250px), 1fr));
    }
    .circle{
       height: 48px;
       width: 48px;
    }
    .polezen{
        text-align: center;
    }
    .nutristeppe{
        height: auto;
        text-align: center;
    }
    .hr{
        transform: none;
         width: 100px;
         margin-bottom: 10px;
    }
    .logoName{
        font-size: 24px;
        text-align: center;
    }
    .mininutisteppe{
        padding: 20px 0px;
        margin-top: 0px;
        background-color: #7466AA;
    }
    .logoDesc{
        font-size: 8px;
    }
    .atlas{
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .power{
        font-size: 8px;
    }
    .min{
        display: none;
    }
}