.main-page{
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    top:0;
    background-image: url('../../images/bg_main.b501351b.jpg');
}
.heading-main{
    height: 200px;
    min-height: fit-content;
    width: 80%;
    flex-direction: row;
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: center;
}
.hr-ver{
    height: 100px;
    width: 1px;
    background-color: white;
    margin-right: 20px;
}
.heading-main span{
    font-size: 30px;
    color:white;
    font-family: Lato,sans-serif;
    text-transform: uppercase;
    font-weight: bold;
}
.main-content-gradient{
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, rgba(69, 2, 255, 0.7) 7.4%, rgba(255, 0, 214, 0.3) 100%);
}
.main-content{
    width: 100%;
    position: fixed;
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: calc( 100% - 140px );
}
.group-logos{
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    position: fixed;
    bottom: 30px;
    width: 90%;
    height:auto;
}
.helper-link{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 250px;
}
.group-logos-item2{
    background: rgba(255, 255, 255, 0.753);
    border-radius:100px;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 80%;
    display: flex;
    max-width: 300px;
    align-items: center;
    justify-content: center;
    height: 48px;
    box-shadow: 0px 0px 10px rgb(34, 34, 34);
}
.group-logos-item1{
    background: linear-gradient(180deg, #5c73c5 0%, #393cddc5 100%);
    border-radius:10px;
    box-sizing: border-box;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    box-shadow: 0 0 20px rgb(255, 254, 254);
}
.logos{
    width:200px;
    height: auto;
}
.group-logos-item1 span{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    padding-top:5px;
    text-transform: uppercase;
    letter-spacing: 0.09em;
    color: white;
}
.group-logos-item2 span{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-weight: bolder;
    font-size: 17px;
    padding-top:5px;
    text-transform: uppercase;
    /* letter-spacing: 0.09em; */
    color: #221e1e;
}
.img{
    height: 60px;
    width: 60px;
}