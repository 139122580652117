.group-links{
    display: inline-flex;
    width: 100%;
    height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.group-link{
    padding-top: 50px;
}
.row,.col-12{
    padding: 0;
    margin: 0;
}
.group-link-span{
    width: 100%;
    text-align: center;
    padding: 0;
}
.ul{
    display:flex;
    flex-direction: row;
    margin-bottom: 0px;
    margin-top: 0px;
    justify-content: space-between;  
}
.li{
    text-decoration: none;
    font-style: normal;
    font-family: Lato,sans-serif;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}
.footer{
    background: #7466AA;
    width: 100%;
    height: auto;
    margin-top: 50px;
    padding: 30px 5% 20px 5%;
}
.inside{
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    background-color: transparent;
}
.shape:hover {
    background-color: #ffffffea;
    box-shadow: 0 0 10px rgba(121, 121, 121, 0.5); 
}
.shape:hover .pSpan{
    color: #7466AA;
}
.shape:hover .spanP{
    color: #3F3C3C;
}
.shape:hover svg path{
    stroke: #3F3C3C;
}
.link{
    text-decoration: none;
}
.shape{
    height: 100px;
    width: 30%;
    display: flex;
    align-content: center;
    padding: 10px 10px 10px 20px;
    margin:0px 10px;
    box-shadow: 0 0 0px rgba(121, 121, 121, 0.5); 
    border: 1px solid rgba(248, 248, 248, 0.671);
    border-radius: 30px;
    cursor: pointer;
}
.textLinks{
    width: 80%;
}
.linkTogo{
    width: 10%;
}
.pSpan{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-size: 21px;
    color:white;
    font-weight: normal;
}
.spanP{
    font-family: Lato,sans-serif;
    letter-spacing: 0.3px;
    font-size: 12px;
    color:rgba(248, 248, 248, 0.89);
    font-weight: lighter;
}
img{
    height: 16px;
    width: 8px;
}
.footerTexts{
    width: 100%;
    text-align: center;
}
.logoName{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    text-align: center;
    color: white;
}
.logoDesc{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight:300;
    font-size: 12px;
    color: white;
    letter-spacing: 0.16em;
}
@media screen and (max-width:600px) {
    .group-links{
        flex-direction: column;
    }
    .shape{
        width: 90%;
        margin: 10px 0px;
    }
    .ul{
        margin-top: 20px;
        margin-bottom: 20px;
    }
}