.navbar{
    width: 100%;
    z-index:99;
    border-bottom: 1px solid #58535348;
    position: fixed;
    top:0;
    left:0;
    height: 80px;
    background-color: white;
}

.setPro{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
}
.navbar-items{
    width: 100%;
    padding: 0px 50px;
    z-index:99;
    position: fixed;
    top:0;
    left:0;
    text-align: center;
    height: 130px;
    background-color:transparent;
} 
.navbar-main{
    width: 100%;
    z-index:99;
    position: fixed;
    top:0;
    left:0;
    height: 50px;
    background-color:transparent;
    }
.hr-small{
    transform: rotate(90deg);
    border: 1px solid rgba(216, 215, 215, 0.829);
    height: 0px;
    border-radius: 1px;
    margin: 0px 10px;
    width: 40px;
}
.logos{
    width:100px;
    display: block;
    height: auto;
}
.group-log-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}
.es{
    width: 250px;
    height: auto;
}
.none{
    display: none;
}
.modal{
    height: 100vh;
    width: 100%;
    display: flex;
    position: fixed;
    top:0;
    left:0;
    z-index:999;
}
.modal .col-7{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.modal-content{
    width:auto;
    height: auto;
    max-width: 250px;
    padding: 10px;
    text-align: center;
    margin-top: 50px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: rgba(19, 18, 18, 0.746);
}
.texts-nav{
    padding: 0;
}
.texts-nav span{
    color: white;
    font-size: 20px;
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: end;
    justify-content: flex-end;
    height: 80px;
    width: 80%;
}
.hr-ver-nav{
    height: 240px;
    width: 24px;
    padding: 0px 10px;
}
.hr-ver-nav .hr{
    height: 240px;
    width: 2px;
    margin-left: 5px;
    transform:none;
    color: white;
}
.hr-ver-nav .ball{
    position: absolute;
    z-index: 56;
    top:40px;
    width: 12px;
    border-radius: 50%;
    height: 12px;
    background-color: #ffffffce;
    border: 1px solid #3F3C3C;
}
.ball:nth-child(1){
    margin-top: 50px;
}
.ball:nth-child(2){
    margin-top: 80px;
}
.ball:last-child{
    margin-top: 160px;
}
.modal-content p:hover{
  background-color: #FFFFFF;
  color: #3F3C3C;
}
.powered{
    display:flex;
    flex-direction: column;
}
.powered span{
    font-size: 20px;
    width: 250px;
    padding-top:5px;
    font-weight: bolder;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    color: #3F3C3C;
}
.row,.col-6,.col-3,.col-2,.col-sm-1,.col-sm-6,.col-md-4,.col-8,.col-sm-10{
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.new-logo{
    width: 70px;
    height: auto;
}
.menu{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.name{
    text-align: center;
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
}
.back{
    width: 10px;
    height: 16px;
    cursor: pointer;
}
@media screen and (max-width:400px){
  .name{
    font-size: 24px;
  }
  .group-log-content{
     flex-direction: column-reverse;
  }
  .hr-small{
     display: none;
  }
}
@media screen and (max-width:300px){
  .name{
    font-size: 20px;
  }
}