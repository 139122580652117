.main{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: #3F3C3C;
    text-align: center;
}
.for100{
    background: #7466AA;
    border-radius: 20px;
    padding: 8px 12px;
    color: white;
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
}
.molecular{
    margin-top: 100px;
}
.heading{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 28px;
    text-align: center;
    color: #3F3C3C;
}
@media screen and (max-width:400px){
    .heading{
        font-size: 24px;
    }
}