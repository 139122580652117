@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
.index{
    margin-top: 150px;
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}
.index-com{
    width: 100%;
    height: auto;
    margin-top: 30px;
    margin-bottom: 50px;
}
.row,.col-12{
    padding: 0;
    margin: 0;
}
.group-content-all{
    display: flex;
    flex-direction: column;
}
.items{
    margin: 0;
}
.group-heading-index{
    display: flex;
    margin-top: 0px;
    margin-bottom: 20px;
    width: 90%;
    margin-left: 5%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}
.list-of-products{
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    overflow-x:auto;
    border-bottom: 1px solid rgba(173, 173, 173, 0.431);
}
::-webkit-scrollbar {
    height: 1px;
    width:1px;
}
.group-desc-set{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}
.group-desc-set span{
    color: #ffffffdc;
    font-family: Lato,sans-serif;
    font-weight: 500;
    font-size: 16px;
    width: auto;
    max-width: 40%;
}
.hr-small-small{
    transform: rotate(90deg);
    height: 2px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    margin: 0px 10px;
    width: 20px;
}
.health-set{
    width: 90%;
    height: auto;
    padding: 20px;
    margin-bottom: 30px;
    margin-top: 30px;
    box-shadow: 0 0 10px rgba(238, 237, 237, 0.5);
    box-sizing: border-box;
    border-radius: 30px;
    background: #6B9A51;
}
.list-of ul{
 list-style: none;
}
.list-of-products img{
    height: 60px;
    width: 60px;
    align-items: center;
    display: flex;
    margin: 0px 13px;
}
.total-weight{
    border-bottom: 1px solid rgba(173, 173, 173, 0.431);
}
.box{
    width: fit-content;
    height:70%;
    overflow-y: auto;
}
.show-modal{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top:0;
    z-index:99999;
    background-color:  #8C969C;
}
.total-weight,.show-all{
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    align-items: center;
    justify-content: space-between;
}
.show-all{
    cursor: pointer;
}
.desc-weight{
    font-family: Lato,sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #3F3C3C;
}
.quan-weight{
    font-family: Lato,sans-serif;
    font-weight: 500;
    font-size: 19px;
    color: #898989;
}
.desc-all{
    font-family: Lato,sans-serif;
    font-size: 18px;
    color: #7466AA;
}
.group-content-list{
    width: 90%;
    height: auto;
    background: #F5F7F6;
    padding: 0px 20px;
    box-shadow: 0 0 10px rgba(238, 237, 237, 0.5); 
    border: 1px solid #e9e5e5;
    box-sizing: border-box;
    border-radius: 30px;
}
.group-btns{
    width: 50px;
    display: flex;
    justify-content: space-around;
}
.desc-health-set{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 20px;
    color: #FFFFFF;
}
.btn1:hover path{
    stroke: #7466AA;
}
.btn1:hover rect{
    stroke: #7466AA;
}
.btn2:hover path{
    stroke: #7466AA;
}
.btn2:hover rect{
    stroke: #7466AA;
}
.factor{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 100%;
}
.row,.col-md-3,.col-sm-4,.col-12,.col-11{
    padding: 0;
    margin: 0;
}
.info-icon{
   width: 32px;
   height: 32px; 
}
.group-gr{
 display: flex;
 flex-direction: row;
}
.gr{
    background: #7466AA;
    border-radius: 16px;
    height: auto;
    width:auto;
    padding: 5px 16px;
    color: white;
    margin: 0 0 0 16px;
    font-family: Lato,sans-serif;
    font-style: normal;
    font-size: 12px;
}
.span{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #3F3C3C;
}
.items{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}
.texts{
    width:80%;
    height:auto;
    margin:0;
    padding-bottom: 30px;
}
.healthIndex{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #3F3C3C;
}
.productDesc{
    font-family: Lato,sans-serif;
    font-size: 20px;
    margin-left: 0%;
    margin-right: 0%;
    color: #3F3C3C;
}
.image{
    height:auto;
    width:90%;
}
.imageContent{
    display: flex;
    justify-content: center;
    justify-items: center;
    width: 100%;
    padding-top: 40px;
    height: auto;
}
.group-property{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: 80%;
    margin-bottom: 40px;
}
.property{
    background-color: #f5f3ffc2;
    border:1px solid #7466AA;
    border-radius: 50px;
    margin: 10px 6px;
    padding: 5px 10px;
    height: auto;
    width:max-content;
    min-width: 60px;
    box-sizing: border-box;
    text-align: center;
}
.property span{
    font-family: Lato,sans-serif;
    font-weight: bolder;
    color:#797575;    
}
.productName{
    width: 100%;
    height:auto;
    margin-left: 0%;
    margin-top: 10px;
    padding-bottom: 30px;
    text-align: center;
}
.iconOfImage{
    margin-top: 20px;
    margin-right: -25px;
}
.icon-star{
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #7466AA;
}
.iconOfImage img{
    height: 12px;
    width: 12px;
}
.name{
    font-family: Lato,sans-serif;
    font-size: 20px;
    font-weight: bolder;
    text-transform: uppercase;
}
.shopId{
    font-family: Lato,sans-serif;
    font-size: 16px;
    color: #3F3C3C;
}
.product-group{
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #3F3C3C;
}
@media screen and (min-width:900px){
    .group-heading-index,.health-set,.group-content-list{
        width: 80%;
    }
    .group-heading-index svg{
        width: 30px;
        height: 30px;
    }
    .group-btns{
        width: 70px;
    }
}
@media screen and (max-width:600px){
  .image{
      width: 128px;
      height: 128px;
  }
  .index-com{
      margin-top: 0px;
  }
  .group-heading-index{
      margin-top: 50px;
  }
  .index{
    margin-top: 70px;
  }
  .texts{
      width: 90%;
      margin-left: 5%;
  }
  .product-group{
      font-size: 20px;
  }
  .factor{
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
  }
  .group-gr{
      margin-left: 0;
      margin-top: 20px;
  }
  .items{
      margin:0px 7%;
  }
  .productDesc{
    font-size: 14px;
  }
}