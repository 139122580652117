.item{
    height: auto;
    width:max-content;
    min-width: 80px;
    border: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 30px;
    text-align: center;
    padding: 10px 30px;
    margin:10px 20px 10px 0px;
}
.howmany{   
    font-size: 18px;  
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    text-align:center;
}
.itemText{
    font-family: Lato,sans-serif;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #3F3C3C;
}